body {
  overflow: hidden;
}

.chakra-stack.datepicker-calendar > div {
  border-width: 0;
}


.contract-wrapper h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 15px;
  text-align: center;
}

.contract-wrapper h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.contract-wrapper p {
  font-size: 16px;
  margin-bottom: 10px;
}

.contract-wrapper a {
  color: #0845dd;
}

.contract-wrapper ol,
.contract-wrapper ul {
  padding-left: 20px;
}

.contract-wrapper ol li,
.contract-wrapper ul li {
  margin-bottom: 10px;
}
